var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing"},[_c('Login',{class:{ translate: _vm.openPopup === 'signin' },on:{"togglePopup":function($event){_vm.openPopup = $event}}}),_c('Register',{class:{ translate: _vm.openPopup === 'register' },on:{"togglePopup":function($event){_vm.openPopup = $event}}}),_c('ForgotPassword',{class:{ translate: _vm.openPopup === 'forgot-password' },on:{"togglePopup":function($event){_vm.openPopup = $event}}}),_c('PageHeader',{on:{"togglePopup":function($event){_vm.openPopup = $event}}}),_c('div',{class:{ popupActive: _vm.openPopup.length > 0 }},[_c('section',{staticClass:"hero"},[_c('img',{staticClass:"hero-img",attrs:{"src":require("@/assets/images/hero-houses.jpg"),"alt":"houses"}}),_c('div',{staticClass:"hero-details"},[_c('div',{staticClass:"hero-details-content"},[_c('div',{staticClass:"content-group"},[_c('h1',[_vm._v(" Earn Big, Remotely with Shelta’s Commission and Incentives by signing up as our Smart Associate. ")]),_c('button',{staticClass:"get-started",on:{"click":function($event){_vm.openPopup = 'register';
                _vm.trackLanding('hero-button');}}},[_vm._v(" Become A Smart Associate "),_c('img',{attrs:{"src":require("@/assets/images/arrow-right.svg"),"alt":""}})])])])]),_c('img',{staticClass:"dots-bg",attrs:{"src":require("@/assets/images/dots-bg.svg"),"alt":""}})]),_vm._m(0),_vm._m(1),_c('section',{staticClass:"section-three"},[_c('div',{staticClass:"left-vertical"}),_c('div',{staticClass:"right-vertical"}),_c('div',{staticClass:"center-vertical"},[_c('div',{staticClass:"counting-dots"},_vm._l((_vm.testimonies),function(testimony,index){return _c('div',{key:index,staticClass:"dot",class:{ active: index === _vm.currentTestimony }})}),0)]),_c('img',{staticClass:"quote",attrs:{"src":require("@/assets/images/quote.svg"),"alt":""}}),_c('button',{staticClass:"left-button",on:{"click":function($event){return _vm.prevTestimony()}}},[_c('img',{attrs:{"src":require("@/assets/images/lessThan.svg"),"alt":""}})]),_c('div',{staticClass:"comments"},[_c('div',{staticClass:"comment"},[_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.testimonies[_vm.currentTestimony].text)+" ")]),_c('h3',{staticClass:"name"},[_vm._v(_vm._s(_vm.testimonies[_vm.currentTestimony].name))]),_c('p',{staticClass:"position"},[_vm._v(_vm._s(_vm.testimonies[_vm.currentTestimony].position))])])]),_c('button',{staticClass:"right-button",on:{"click":function($event){return _vm.nextTestimony()}}},[_c('img',{attrs:{"src":require("@/assets/images/greaterThan.svg"),"alt":""}})])]),_vm._m(2),_c('section',{staticClass:"section-five"},[_c('h2',[_vm._v("Join us, lets create value together!")]),_c('button',{on:{"click":function($event){_vm.openPopup = 'register';
          _vm.trackLanding('hero-button');}}},[_vm._v(" Become a Smart Associate "),_c('img',{attrs:{"src":require("@/assets/images/arrow-white.svg"),"alt":""}})])]),_c('footer',{staticClass:"footer"},[_vm._m(3),_c('div',{staticClass:"footerBg"}),_vm._m(4),_c('p',{staticClass:"copyright"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" A product of shelta ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-one"},[_c('h2',[_vm._v("Start earning in 3 easy steps")]),_c('div',{staticClass:"steps"},[_c('div',{staticClass:"step"},[_c('img',{attrs:{"src":require("@/assets/images/account.svg"),"alt":""}}),_c('h3',[_vm._v("Create a free account")]),_c('p',[_vm._v(" Register for a free online account, get verified and approved within 48 hours ")])]),_c('div',{staticClass:"step"},[_c('img',{attrs:{"src":require("@/assets/images/search-user.svg"),"alt":""}}),_c('h3',[_vm._v("Refer landlords, recruit others")]),_c('p',[_vm._v(" Get landlords to list their properties on our platform and/or recruit others to also advocate and enjoy several other benefits. ")])]),_c('div',{staticClass:"step"},[_c('img',{attrs:{"src":require("@/assets/images/money-bag.svg"),"alt":""}}),_c('h3',[_vm._v("Get paid commission")]),_c('p',[_vm._v(" Earn commission from deals on properties you help get listed on our platform. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-two"},[_c('div',[_c('h2',[_vm._v("Awesome benefits, just for you")]),_c('div',{staticClass:"benefit"},[_c('img',{attrs:{"src":require("@/assets/images/checked.svg"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Earn Direct Commission")]),_c('p',[_vm._v(" Earn direct commission in cash and points on transactions carried out through you. ")])])]),_c('div',{staticClass:"benefit"},[_c('img',{attrs:{"src":require("@/assets/images/checked.svg"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Earn Referral Commission")]),_c('p',[_vm._v(" Earn commission in points on transactions carried out through your recruited Smart Associates. ")])])]),_c('div',{staticClass:"benefit"},[_c('img',{attrs:{"src":require("@/assets/images/checked.svg"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Enjoy Amazing Incentives")]),_c('p',[_vm._v(" You enjoy amazing incentives as bonuses from the volume of transaction generated by you and your recruits. ")])])]),_c('div',{staticClass:"benefit"},[_c('img',{attrs:{"src":require("@/assets/images/checked.svg"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Networking")]),_c('p',[_vm._v(" Gain an opportunity to connect and network with like minds in the Real Estate/Housing sector. ")])])]),_c('div',{staticClass:"benefit"},[_c('img',{attrs:{"src":require("@/assets/images/checked.svg"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Access to Shelta Property Portfolios")]),_c('p',[_vm._v(" You are granted access to our property portfolios and briefs to earn agency fees. ")])])])]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/investment.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-four"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"section-four-head"},[_c('h2',[_vm._v(" Enjoy benefits as a "),_c('span',{staticClass:"shelta"},[_vm._v("Smart")]),_vm._v(" Associate ")])]),_c('p',[_vm._v(" Earn as high up as 40% commission on every transaction you carry out! Limited offer for up to 12 months. Sign up now while offer lasts. T&C applies. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"help",attrs:{"href":"https://api.whatsapp.com/send?phone=9088438651&text=Hello%2C%20I%20want%20more%20info%20about%20becoming%20a%20Smart%20Associate"}},[_c('p',[_vm._v("Help")]),_c('div',{staticClass:"question-icon"},[_vm._v("?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-links"},[_c('a',{attrs:{"href":"mailto:smart@myshelta.com"}},[_c('i',{staticClass:"far fa-envelope",staticStyle:{"color":"white"}})]),_c('a',{attrs:{"href":"tel:+2349088438651"}},[_c('i',{staticClass:"fa-solid fa-phone",staticStyle:{"color":"white"}})]),_c('a',{attrs:{"href":"#facebook"}},[_c('img',{attrs:{"src":require("@/assets/images/facebook.svg"),"alt":"facebook"}})]),_c('a',{attrs:{"href":"#instagram"}},[_c('img',{attrs:{"src":require("@/assets/images/instagram.svg"),"alt":"facebook"}})]),_c('a',{attrs:{"href":"#twitter"}},[_c('img',{attrs:{"src":require("@/assets/images/twitter.svg"),"alt":"facebook"}})]),_c('a',{attrs:{"href":"#linkedin"}},[_c('img',{attrs:{"src":require("@/assets/images/linkedin.svg"),"alt":"facebook"}})])])
}]

export { render, staticRenderFns }
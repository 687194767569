<template>
  <div class="landing">
    <Login
      v-bind:class="{ translate: openPopup === 'signin' }"
      @togglePopup="openPopup = $event"
    />
    <Register
      v-bind:class="{ translate: openPopup === 'register' }"
      @togglePopup="openPopup = $event"
    />
    <ForgotPassword
      v-bind:class="{ translate: openPopup === 'forgot-password' }"
      @togglePopup="openPopup = $event"
    />
    <PageHeader @togglePopup="openPopup = $event" />

    <div :class="{ popupActive: openPopup.length > 0 }">
      <section class="hero">
        <img
          src="@/assets/images/hero-houses.jpg"
          alt="houses"
          class="hero-img"
        />
        <div class="hero-details">
          <div class="hero-details-content">
            <div class="content-group">
              <h1>
                Earn Big, Remotely with Shelta’s Commission and Incentives by
                signing up as our Smart Associate.
              </h1>

              <button
                class="get-started"
                @click="
                  openPopup = 'register';
                  trackLanding('hero-button');
                "
              >
                Become A Smart Associate
                <img src="@/assets/images/arrow-right.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <img class="dots-bg" src="@/assets/images/dots-bg.svg" alt="" />
      </section>

      <section class="section-one">
        <h2>Start earning in 3 easy steps</h2>
        <div class="steps">
          <div class="step">
            <img src="@/assets/images/account.svg" alt="" />
            <h3>Create a free account</h3>
            <p>
              Register for a free online account, get verified and approved
              within 48 hours
            </p>
          </div>

          <div class="step">
            <img src="@/assets/images/search-user.svg" alt="" />
            <h3>Refer landlords, recruit others</h3>
            <p>
              Get landlords to list their properties on our platform and/or
              recruit others to also advocate and enjoy several other benefits.
            </p>
          </div>

          <div class="step">
            <img src="@/assets/images/money-bag.svg" alt="" />
            <h3>Get paid commission</h3>
            <p>
              Earn commission from deals on properties you help get listed on
              our platform.
            </p>
          </div>
        </div>
      </section>

      <section class="section-two">
        <div>
          <h2>Awesome benefits, just for you</h2>
          <div class="benefit">
            <img src="@/assets/images/checked.svg" alt="" />
            <div>
              <h3>Earn Direct Commission</h3>
              <p>
                Earn direct commission in cash and points on transactions
                carried out through you.
              </p>
            </div>
          </div>
          <div class="benefit">
            <img src="@/assets/images/checked.svg" alt="" />
            <div>
              <h3>Earn Referral Commission</h3>
              <p>
                Earn commission in points on transactions carried out through
                your recruited Smart Associates.
              </p>
            </div>
          </div>
          <div class="benefit">
            <img src="@/assets/images/checked.svg" alt="" />
            <div>
              <h3>Enjoy Amazing Incentives</h3>
              <p>
                You enjoy amazing incentives as bonuses from the volume of
                transaction generated by you and your recruits.
              </p>
            </div>
          </div>
          <div class="benefit">
            <img src="@/assets/images/checked.svg" alt="" />
            <div>
              <h3>Networking</h3>
              <p>
                Gain an opportunity to connect and network with like minds in
                the Real Estate/Housing sector.
              </p>
            </div>
          </div>
          <div class="benefit">
            <img src="@/assets/images/checked.svg" alt="" />
            <div>
              <h3>Access to Shelta Property Portfolios</h3>
              <p>
                You are granted access to our property portfolios and briefs to
                earn agency fees.
              </p>
            </div>
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/investment.svg" alt="" />
        </div>
      </section>

      <section class="section-three">
        <div class="left-vertical"></div>
        <div class="right-vertical"></div>
        <div class="center-vertical">
          <div class="counting-dots">
            <div
              class="dot"
              v-for="(testimony, index) in testimonies"
              :key="index"
              :class="{ active: index === currentTestimony }"
            ></div>
          </div>
        </div>
        <img src="@/assets/images/quote.svg" class="quote" alt="" />

        <button class="left-button" @click="prevTestimony()">
          <img src="@/assets/images/lessThan.svg" alt="" />
        </button>
        <div class="comments">
          <div class="comment">
            <p class="text">
              {{ testimonies[currentTestimony].text }}
            </p>
            <h3 class="name">{{ testimonies[currentTestimony].name }}</h3>
            <p class="position">{{ testimonies[currentTestimony].position }}</p>
          </div>
        </div>
        <button class="right-button" @click="nextTestimony()">
          <img src="@/assets/images/greaterThan.svg" alt="" />
        </button>
      </section>

      <section class="section-four">
        <!-- <img src="@/assets/images/phone.svg" alt="enjoy benefits" /> -->
        <div class="content">
          <div class="section-four-head">
            <h2>
              Enjoy benefits as a <span class="shelta">Smart</span> Associate
            </h2>
          </div>
          <p>
            Earn as high up as 40% commission on every transaction you carry
            out! Limited offer for up to 12 months. Sign up now while offer
            lasts. T&C applies.
          </p>
        </div>
      </section>

      <section class="section-five">
        <h2>Join us, lets create value together!</h2>
        <button
          @click="
            openPopup = 'register';
            trackLanding('hero-button');
          "
        >
          Become a Smart Associate
          <img src="@/assets/images/arrow-white.svg" alt="" />
        </button>
      </section>

      <footer class="footer">
        <!-- TODO: Add a link to this help div -->
        <a
          href="https://api.whatsapp.com/send?phone=9088438651&text=Hello%2C%20I%20want%20more%20info%20about%20becoming%20a%20Smart%20Associate"
          class="help"
        >
          <p>Help</p>
          <div class="question-icon">?</div>
        </a>

        <div class="footerBg"></div>
        <div class="social-links">
          <a href="mailto:smart@myshelta.com">
            <i class="far fa-envelope " style="color: white"></i>
          </a>
          <a href="tel:+2349088438651">
            <i class="fa-solid fa-phone" style="color: white"></i>
          </a>
          <a href="#facebook">
            <img src="@/assets/images/facebook.svg" alt="facebook" />
          </a>
          <a href="#instagram">
            <img src="@/assets/images/instagram.svg" alt="facebook" />
          </a>
          <a href="#twitter">
            <img src="@/assets/images/twitter.svg" alt="facebook" />
          </a>
          <a href="#linkedin">
            <img src="@/assets/images/linkedin.svg" alt="facebook" />
          </a>
        </div>
        <p class="copyright">
          {{ new Date().getFullYear() }} A product of shelta
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import Login from "./Authentication/Login";
import Register from "./Authentication/Register";
import ForgotPassword from "./Authentication/ForgotPassword";
import PageHeader from "./Header/PageHeader";
import "@fortawesome/fontawesome-free/css/all.css";

export default {
  name: "Landing",
  data() {
    return {
      openPopup: "",
      testimonies: [
        {
          text:
            "I have been trying to figure out a way to generate income remotely, after I had my son and had to become a housewife. My experience so far since I signed up as a SMART associate through a good friend of mine, has been nothing short of amazing. I am grateful to SHELTA for taking me onboard as an associate.",
          name: "- Mrs. Chinonye Ugwoke"
        },
        {
          text:
            "Honestly, working a 9-5 job in Nigeria can be tiring and ultimately exhausting if you are not being paid well. Since signing up as a SMART associate, I have been earning massively from my commissions, sometimes I am lucky enough to earn twice my monthly salary from one closed deal, this is by far one of the best decisions I made this year.",
          name: "- Engr Philip Bassey"
        }
      ],
      currentTestimony: 0
    };
  },
  components: {
    PageHeader,
    Login,
    Register,
    ForgotPassword
  },
  methods: {
    nextTestimony() {
      if (this.currentTestimony < this.testimonies.length - 1) {
        this.currentTestimony += 1;
      } else {
        this.currentTestimony = 0;
      }
    },
    prevTestimony() {
      if (this.currentTestimony > 0) {
        this.currentTestimony -= 1;
      } else {
        this.currentTestimony = this.testimonies.length - 1;
      }
    },

    trackLanding(tag) {
      this.$gtag.event(tag, {
        event_category: "SMART LANDING CLICKS",
        event_label: tag,
        value: 1
      });
    }
  },
  created() {
    if (this.$router.currentRoute.path.includes("/R")) {
      this.openPopup = "register";
    }
  }
};
</script>

<style scoped>
.landing {
  position: relative;
}
.popupActive {
  /* this style is to make the landing page height decrease to fit the authentication
            popup height, at the same time make it have the side scroll */
  max-height: calc(100vh - 69px);
  overflow: hidden;
}
.translate {
  transform: translateY(0);
}

/* hero section */
.hero {
  display: flex;
  align-items: center;
  position: relative;
}
.hero-img {
  max-width: 100%;
  min-height: 500px;
}
.hero-details {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 2;
}
.hero-details-content {
  background: rgba(var(--primary-rgb), 0.8);
  width: 720px;
  max-width: 100%;
  margin: 0 50px 0 auto;
  padding: 32px 0;
}
.hero-details-content .content-group {
  margin-left: 15%;
}
.hero-details-content h1,
.hero-details-content p {
  font-weight: 500;
  line-height: 1.39;
  font-family: "Gotham-Medium";
  color: #fff;
}
.hero-details-content h1 {
  font-size: 35px;
  max-width: 478px;
}
.hero-details-content p {
  margin-top: 57px;
  font-size: 20px;
  max-width: 345px;
}
.get-started {
  width: 317px;
  height: 70px;
  background: #fff;
  border: 0;
  color: var(--primary-color);
  font-size: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Gotham-Book";
  margin-top: 83px;
  padding: 10px;
}
.get-started:focus {
  outline: 2px solid rgba(var(--primary-light-rgb), 0.5);
}
.dots-bg {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* section one styling */
.section-one {
  padding: 90px 50px 0;
}
.section-one h2 {
  font-size: 30px;
  position: relative;
  margin-bottom: 84px;
}
.section-one h2::after {
  content: "";
  display: inline-block;
  width: 142px;
  height: 10px;
  background: var(--primary-color);
  position: absolute;
  left: 0;
  bottom: -20px;
}
.section-one .steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.step {
  flex-basis: 335px;
  margin-bottom: 50px;
}
.step > img {
  margin-bottom: 25px;
}
.step > h3 {
  font-family: "Gotham-Book";
  font-weight: bolder;
  font-size: 22px;
  margin-bottom: 15px;
}
.step > p {
  font-family: "Gotham-Book";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  opacity: 0.8;
}

/* section-two styling */
.section-two {
  background: url("../assets/images/skyBg.jpg") no-repeat;
  background-size: cover;
  background-position: right top;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 50px 0;
}

.section-two > * {
  flex: 1;
}

.img img {
  width: 100%;
}
.section-two h2 {
  color: #000;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 62px;
  position: relative;
}
.section-two h2::after {
  content: "";
  display: inline-block;
  width: 142px;
  height: 10px;
  background: var(--primary-color);
  position: absolute;
  left: 0;
  bottom: -22px;
}
.benefit {
  display: flex;
  max-width: 486px;
  margin-bottom: 40px;
}
.benefit:last-of-type {
  margin-bottom: 56px;
}
.benefit img {
  margin-right: 15px;
  margin-top: 0;
  align-self: flex-start;
}
.benefit h3 {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 15px;
  font-family: "Gotham-Book";
  font-weight: 900;
}
.benefit p {
  font-size: 18px;
  line-height: 1.3;
  font-family: "Gotham-Book";
  font-weight: 600;
  opacity: 0.8;
}

/* section-three styling */
.section-three {
  margin-top: 62px;
  height: 526px;
  background: url("../assets/images/union.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.left-vertical,
.right-vertical {
  background: rgba(var(--primary-light-rgb, 0.5));
  height: 100%;
  position: absolute;
  width: 53px;
  top: 0;
}
.left-vertical {
  left: 0;
}
.right-vertical {
  right: 0;
}
.center-vertical {
  width: 600px;
  height: 100%;
  border-style: solid;
  border-width: 60px;
  border-color: rgba(var(--primary-light-rgb, 0.5)) transparent
    rgba(var(--primary-light-rgb, 0.5)) transparent;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
}
.counting-dots {
  height: 60px;
  width: 100%;
  margin-bottom: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  border-radius: 50%;
  background: #c4c4c4;
  width: 10px;
  height: 10px;
  margin-right: 20px;
  margin-left: 20px;
}
.active {
  background: var(--primary-color);
}
.quote {
  position: absolute;
  top: 0;
  width: 145px;
  left: calc((100% - 145px) / 2);
}
.left-button,
.right-button {
  background: #fff;
  border: none;
  height: 121px;
  width: 78px;
  position: absolute;
  z-index: 5;
  top: calc((100% - 121px) / 2);
  box-shadow: 15px 10px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-button {
  border-radius: 0px 50px 50px 0px;
  left: 0;
  padding-right: 20px;
}
.right-button {
  border-radius: 50px 0px 0px 50px;
  right: 0;
  padding-left: 20px;
}
.left-button:focus,
.right-button:focus {
  outline: 0;
  border: 2px solid dodgerblue;
}
.comments {
  position: absolute;
  top: 0;
  color: #fff;
  height: 100%;
  width: 100%;
  padding: 10% 12%;
}
.comment {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.comment .text {
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 25px;
}
.comment .name {
  font-size: 20px;
  margin-bottom: 10px;
}
.comment .position {
  font-size: 14px;
}

/* section-four styling */
.section-four {
  padding: 0 53px;
  min-height: 597px;
  background: url("../assets/images/happy_customer.jpg") no-repeat;
  background-position: center;
  background-color: white;
  background-size: 80%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.section-four::after {
  content: "";
  position: absolute;
  background-color: white;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.section-four > img {
  margin-left: 95px;
  margin-bottom: -100px;
  margin-right: 40px;
}
.shelta {
  color: var(--primary-color);
  font-family: "Gotham-Book";
  font-weight: 900;
}
.content {
  max-width: 550px;
  align-self: center;
  margin-top: 50px;
}
.section-four h2 {
  font-size: 30px;
  line-height: 1.21;
  position: relative;
  /* padding: 4rem 0; */
}

.section-four-head {
  position: relative;
  margin-bottom: 2rem;
}
.section-four-head::after {
  content: "";
  display: inline-block;
  width: 84px;
  height: 12px;
  background: var(--primary-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.section-four p {
  font-size: 24px;
  line-height: 1.21;
  font-weight: 600;
}

/* section-five styling */
.section-five {
  background: url("../assets/images/skyscraper.jpg");
  background-position: center;
  background-size: cover;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-five h2 {
  color: #fff;
  font-size: 36px;
  line-height: 1.4;
  font-family: "Gotham-Book";
  font-weight: 700;
  margin: 40px 0 36px 0;
  text-align: center;
  max-width: 80%;
}
.section-five button {
  width: 317px;
  height: 70px;
  background: var(--primary-color);
  color: #fff;
  border: 0;
  font-size: 24px;
  font-family: "Gotham-Book";
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.section-five button:focus {
  outline: 2px solid lightblue;
}
.section-five button img {
  margin-left: 10px;
}

/* footer styling */
.footer {
  margin-top: 58px;
  height: 80px;
  background: #fff;
  position: relative;
  overflow: hidden;
}
.footerBg {
  position: absolute;
  background: var(--primary-color);
  border-radius: 50%;
  width: 100%;
  height: 350px;
  top: 0;
}
.footer .help {
  width: 90px;
  height: 45px;
  border-radius: 22.5px;
  background: var(--primary-color);
  color: #fff;
  font-size: 12px;
  position: absolute;
  right: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Gotham-Medium";
  z-index: 1;
}
.help p {
  margin-left: 10px;
}

.footer .question-icon {
  border-radius: 50%;
  width: 36.35px;
  height: 36.35px;
  background: rgba(255, 255, 255, 0.9);
  color: var(--primary-color);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .social-links {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 10px 0;
  position: relative;
}
.footer .social-links a {
  width: 26.55px;
  height: 26.55px;
  border: 1px solid #fff;
  border-radius: 3px;
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .copyright {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.05em;
  position: relative;
  text-align: center;
}

@media (max-width: 1199.98px) {
  /* section-two styling */
}

@media (max-width: 991.98px) {
  /* hero section */
  .hero-details-content {
    margin: 0 0 0 auto;
  }

  /* section-two styling */
  .section-two .img {
    display: none;
  }

  /* section-three styling */
  .quote {
    width: 100px;
    left: calc((100% - 100px) / 2);
  }
  .center-vertical {
    width: 400px;
    border-width: 42px;
  }
  .counting-dots {
    height: 42px;
    margin-bottom: -42px;
  }
  .left-button,
  .right-button {
    height: 100px;
    width: 68px;
  }
  .left-button {
    border-radius: 0px 40px 40px 0px;
    padding-right: 10px;
  }
  .right-button {
    border-radius: 40px 0px 0px 40px;
    padding-left: 10px;
  }
  .comments {
    padding: 10% 15%;
  }
  .comment .text {
    font-size: 16px;
    line-height: 1.5;
  }
  .comment .name {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .comment .position {
    font-size: 14px;
  }

  /* section-four styling */
  .section-four > img {
    margin-left: 0;
    width: 270px;
  }
  .section-four h2 {
    font-size: 26px;
  }
  .section-four p {
    font-size: 20px;
    line-height: 1.3;
  }

  /* section-five styling */
  .section-five h2 {
    font-size: 32px;
  }
  .section-five button {
    width: 300px;
    height: 60px;
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  /* hero section */
  .hero-details-content {
    background: rgba(var(--primary-rgb), 0.4);
    min-height: unset;
  }
  .hero-details-content h1 {
    font-size: 30px;
  }
  .dots-bg {
    display: none;
  }

  /* section-two styling */
  .section-two h2 {
    font-size: 24px;
  }
  .benefit h3 {
    font-size: 18px;
  }
  .benefit p {
    font-size: 16px;
  }

  /* section-four styling */
  .section-four > img {
    width: 200px;
    margin-right: 20px;
  }
  .section-four h2 {
    font-size: 26px;
  }
  .section-four p {
    font-size: 20px;
    line-height: 1.3;
  }

  /* section-five styling */
  .section-five h2 {
    margin-top: 70px;
    font-size: 28px;
  }
  .section-five button {
    width: 280px;
    height: 60px;
    font-size: 20px;
  }

  /* footer styling */
  .footer {
    height: 110px;
  }
  .footerBg {
    top: 30px;
  }
  .footer .help {
    right: 15px;
  }
  .footer .social-links {
    margin: 50px 0 10px;
  }
}

@media (max-width: 575.98px) {
  /* hero section */
  .hero-details-content .content-group {
    width: 90%;
    margin: 0 auto;
  }
  .hero-details-content h1 {
    text-align: center;
    max-width: unset;
  }
  .hero-details-content p {
    text-align: center;
    max-width: unset;
  }
  .get-started {
    width: 250px;
    height: 50px;
    font-size: 20px;
    margin: 83px auto 0;
  }

  /* section-one styling */
  .section-one {
    padding: 60px 25px;
  }

  /* section-two styling */
  .section-two {
    padding: 50px 25px 0;
  }

  /* section-three styling */
  .left-vertical,
  .right-vertical {
    width: 26px;
  }
  .quote {
    width: 60px;
    left: calc((100% - 60px) / 2);
  }
  .center-vertical {
    width: 250px;
    border-width: 25px;
  }
  .counting-dots {
    height: 25px;
    margin-bottom: -25px;
  }
  .left-button,
  .right-button {
    height: 80px;
    width: 58px;
  }
  .left-button {
    border-radius: 0px 30px 30px 0px;
  }
  .right-button {
    border-radius: 30px 0px 0px 30px;
  }
  .comments {
    padding: 10% 20%;
  }

  /* section-four styling */
  .section-four {
    flex-direction: column;
    height: fit-content;
    background-size: cover;
    padding: 50px 25px 50px 25px;
  }
  .section-four > img {
    width: 200px;
    margin: 0 auto;
  }
  .section-four h2 {
    font-size: 26px;
  }
  .section-four p {
    font-size: 20px;
    line-height: 1.3;
  }
}

@media (max-width: 424.98px) {
  /* hero section */
  .hero-details-content .content-group {
    width: 95%;
  }

  /* section-two styling */
  .benefit img {
    width: 25px;
    margin-right: 10px;
  }
}

@media (max-width: 374.98px) {
  /* hero section */
  .hero-details-content h1 {
    font-size: 26px;
  }
  .hero-details-content p {
    font-size: 18px;
  }

  /* section-three styling */
  .comment .text {
    font-size: 14px;
    line-height: 1.7;
  }
  .comment .name {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .comment .position {
    font-size: 14px;
  }

  /* section-five styling */
  .section-five h2 {
    font-size: 26px;
  }
  .section-five button {
    width: 260px;
    height: 55px;
    font-size: 18px;
  }
}
</style>
